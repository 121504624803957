.body {
  background: #fff;
}

.anchor {
  padding-bottom: 64px;
}

.navigation {
  box-shadow: 0 -1px 4px 0 rgba(106, 106, 106, 0.5);
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  width: 100%;
}

@media (max-width: 991px) {
  .anchor {
    padding-bottom: 70px;
  }
}
