.mainMenu {
  min-height: 72px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.leftSection {
  height: 100%;
  display: flex;
  align-items: center;
}

.desktopMenu {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.desktopMenu a {
  color: #3d3d3d;
}

.desktopMenu a.currentLink {
  color: #173f8f;
}

.menuItem {
  position: relative;
}

.menuItem:hover > .menuSubitems {
  opacity: 1;
  height: 100px;
  padding: 12px;
}

.menuTrigger {
  cursor: default;
}

.arrowDown {
  width: 16px;
  height: 16px;
  color: #3d3d3d;
  margin-left: 4px;
  transition: all 300ms;
}

.menuItem:hover > .menuTrigger .arrowDown {
  transform: rotate(180deg);
}

.menuSubitems {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px #1313131a;
  flex-direction: column;
  gap: 10px;
  padding: 0 12px;
  min-width: 170px;
  transition: all 300ms;
  display: flex;
  opacity: 0.5;
  height: 0px;
  overflow: hidden;
  border-radius: 4px;
}

@media (min-width: 980px) {
  .menuButton {
    display: none;
  }
  .mobileLogo {
    display: none;
  }
}

@media (max-width: 1140px) {
  .desktopMenu {
    gap: 10px;
  }
}

@media (max-width: 979px) {
  .desktopMenu {
    display: none;
  }
  .desktopLogo {
    display: none;
  }
  .help {
    display: none;
  }
}

.leftSection button {
  background-color: inherit;
  padding: 0;
  border: none;
  outline: none;
}

.leftSection > button > span {
  display: block;
  width: 25px;
  height: 3px;
  background: #696969;
  border-radius: 30px;
  outline: none;
}

.leftSection > button > span:not(:nth-child(3)) {
  margin-bottom: 4px;
}

.leftSection:focus {
  outline: none;
}

.centerSection {
  justify-self: center;
}

.logo {
  height: 40px;
  margin-bottom: 10px;
}

.icon {
  height: 30px;
}

.rightSection {
  display: flex;
  justify-self: right;
  align-items: center;
  justify-content: space-between;
}

.rightSection a {
  color: #173f8f;
  font-weight: 600;
  line-height: 1.33;
}

@media (max-width: 991px) {
  .mainMenu {
    min-height: 70px;
  }
}

@media (max-width: 575px) {
  .rightSection {
    justify-self: auto;
    justify-content: space-around;
  }
}
