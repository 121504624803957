body {
  font-family: 'Poppins';
  font-display: initial;
}

img {
  aspect-ratio: attr(width) / attr(height);
}

@media (max-width: 767px) {
  html {
    font-size: 92.5%;
  }
}

@media (max-width: 575px) {
  html {
    font-size: 87.5%;
  }
}
