.header {
  background-color: #f4f4f4;
  color: #0b4178;
  font-family: Poppins;
  font-weight: 600;
  padding: 30px 0 80px 0;
}

.container {
  max-width: 892px;
}

.container h1 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 1.375rem;
}

.body {
  margin-top: 30px;
  border-radius: 3px;
  border: solid 1px rgba(194, 207, 221, 0.5);
  margin: auto;
}

.body h2 {
  font-size: 1.125rem;
}

.body .active {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #ffffff;
  padding-left: 0;
  padding-right: 0;
}

.body .inactive {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #e9e9e9;
  padding-left: 0;
  padding-right: 0;
  color: #a5a5a5;
}

.body h5,
.body h6 {
  font-weight: 600;
}

.active h5 span {
  border-bottom: solid 2px #0b4178;
  padding-bottom: 6px;
}

h5 span {
  cursor: pointer;
}

.bodyContent {
  background-color: #ffffff;
  padding-bottom: 40px;
}

.forgotPasswordBtn a {
  font-size: 0.75rem;
  color: #0b4178;
  text-decoration: none;
}

.signInBtn button {
  border-radius: 3px;
  border: solid 1px #0b4178;
  background-color: #0b4178;
  color: #ffffff;
  padding: 10px 40px;
  font-size: 1rem;
}

.signInMobileBtn a {
  font-size: 0.9375rem;
  font-weight: 500;
  color: #0b4178;
  text-decoration: none;
}

.bodyContent p {
  font-size: 0.9375rem;
  font-weight: 500;
  color: #0b4178;
}

.signInMobileBtn svg {
  fill: #0b4178;
}

.haveAccountTitle {
  display: flex;
  justify-content: space-between;
}

.haveAccountTitle > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 40px;
  border: 1px solid #ffb000;
  border-radius: 3px;
  color: #ffb000;
  font-size: 0.9375rem;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.02px;
}

.haveAccountTitle > a:focus,
.haveAccountTitle > a:hover {
  text-decoration: none;
}

/* Input css */
div.inputGroup {
  position: relative;
  border-bottom: solid 1px #e5e5e5;
  font-weight: 500;
  color: #3d3d3d;
}

.errorBottom {
  border-bottom: solid 1px #ff1010 !important;
}

div.inputGroup label {
  display: block;
  padding-top: 20px;
}

div.error {
  font-size: 0.75rem;
  font-weight: normal;
  color: #ff1010;
}

.inputUnderlined > input {
  border: none;
  width: 100%;
  font-size: 1.0625rem;
  line-height: 147.6%;
  color: #0b4178;
}

.inputUnderlined > input:focus {
  outline: none;
}

.inputUnderlined > .inputLabel {
  position: absolute;
  top: 1.5rem;
  left: 0;
  line-height: 147.6%;
  color: #3d3d3d;
  transition: top 0.2s;
}

.inputUnderlined > input:focus + .inputLabel,
.inputUnderlined > .presenceField + .inputLabel {
  top: 0;
  font-size: 0.8rem;
  margin-bottom: 32px;
}

.bookingIdConfirmationInformation p {
  color: #a5a5a5;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2;
  margin-bottom: 14px;
}

.inline {
  display: flex;
}

.inline > div {
  flex: 1;
}

.inline > div:nth-of-type(1) {
  margin-right: 32px;
}

.calendarIcon {
  position: absolute;
  right: 0;
}

.dayPicker {
  display: inline-block;
  position: absolute;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 7px 15px 18px -8px rgba(87, 87, 87, 0.5);
  border: solid 1px #ffffff;
  background-color: #ffffff;
}

.inputBackground {
  background-color: inherit;
}

.arrowNext {
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 22px;
  width: 26px;
  height: 26px;
  padding-left: 3px;
  background: #c2cfdd;
  border-radius: 50%;
  top: 0;
  text-align: center;
}

.arrowPrev {
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 22px;
  width: 26px;
  height: 26px;
  background: #c2cfdd;
  border-radius: 50%;
  top: 0;
  text-align: center;
}

.arrowNext > svg g,
.arrowPrev > svg g {
  fill: #fff;
}

.closeIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 4px;
  top: 50%;
}

.closeIconWrapper > svg {
  right: 22px;
  top: 0;
  bottom: 0;
  width: 14px;
  height: 14px;
  cursor: pointer;
  stroke: #ccc;
  stroke-width: 3px;
  fill: #ccc;
}

.select {
  max-height: 380px;

  overflow-y: auto;
  overflow-x: hidden;

  text-overflow: ellipsis;

  position: absolute;
  right: -28.5px;
  left: -28.5px;

  background-color: #fff;
  border: solid 1px #fff;
  box-shadow: 0 5px 10px 0 rgba(89, 89, 89, 0.5);
  z-index: 1000;
}

.options {
  margin: 15px 16px;
}

.location,
.city {
  font-size: 1.125rem;
  line-height: 24px;

  width: 100%;
  padding: 10px 0;

  display: flex;
  justify-content: space-between;

  cursor: pointer;
}

.country {
  font-size: 0.75rem;
  line-height: 24px;
  color: #a5a5a5;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.location > div,
.city > div {
  max-width: 80%;
  display: flex;
  align-items: center;
}

.location > div > svg,
.city > div > svg,
.location > svg {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
}

.location > div > p,
.city > div > p,
.locations > .location > p {
  margin-left: 11px;
  margin-bottom: 0;
  color: #3d3d3d;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.locations > .location {
  padding-left: 10px;
  justify-content: flex-start;
}

.locations > .location > div {
  padding-left: 11px;
  max-width: 100%;
}

@media (max-width: 779px) {
  .select {
    width: 305px;
  }

  .location > div,
  .city > div {
    max-width: 75%;
  }
}

@media (max-width: 800px) {
  .inline {
    flex-direction: column;
  }

  .inline > div:nth-of-type(1) {
    margin-right: 0;
  }

  .termsAndSubmit > button {
    font-size: 0.75rem;
  }
}
