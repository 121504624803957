.block {
  padding-top: 70px;
  padding-bottom: 50px;
  background-color: #0b4178;
  text-align: left;
}

.block h3 {
  color: #fff;
}

.linksHeader {
  color: #c2cfdd;
  font-size: 0.9rem;
  font-weight: 600;
  font-size: 1rem;
}

.block hr {
  border-color: white;
  opacity: 0.08;
  margin-right: 25px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.links > a {
  display: block;
  line-height: 1.8;
  letter-spacing: normal;
  color: #ffffff;
  font-size: 1rem;
}

.links a:hover {
  color: #ffb000;
  text-decoration: none;
}

.copyrights {
  background-color: #0b4178;
}

.copyrights > div {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 20px;
}

.links .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links .title > button {
  background: transparent;
  border: none;
  color: #fff;
  padding-left: 0;
  flex: 1;
  text-align: left;
  font-weight: 600;
}

.links .title > button:focus {
  outline: none;
}

.links .title > button:hover {
  color: #ffb000;
}

.plus {
  margin-right: 25px;
  width: 16px;
  height: 16px;
  color: #ffb000;
}

.content {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 25px;
  font-size: 0.875rem;
  color: #fff;
}

.logo {
  width: 260px;
}

.socialIconsContainer {
  display: flex;
  padding-top: 6px;
}

.socialIconsContainer a + a {
  margin-left: 16px;
}

.socialIconsContainer svg {
  width: 24px;
  height: 24px;
  transition: color 0.3s;
}

.socialIconsContainer svg:hover {
  color: #ffb000;
}

@media (max-width: 767px) {
  .plus {
    display: none;
  }

  .block hr {
    margin-right: 0;
  }

  .content {
    padding-right: 10px;
  }

  .logo {
    width: 165px;
  }

  .linksHeader {
    text-align: center;
    margin-top: 20px;
  }

  .links .title button {
    text-align: center;
  }

  .links {
    text-align: center;
  }

  .socialIconsContainer {
    justify-content: center;
  }

  .newsletterLabel {
    font-size: 1.25rem;
  }
}
