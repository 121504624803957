.container {
  max-width: 1220px;
}

.container-sm {
  max-width: 960px;
}

.d-flow-root {
  display: flow-root;
}
