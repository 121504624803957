.DayPicker {
  border-radius: 0;
  box-shadow: none;
}

.BirthDatePickerSignUp .CalendarMonthGrid_month__hideForAnimation + .CalendarMonthGrid_month__horizontal {
  border-right: 0;
}

.lostBookingIdSingleDatePicker .CalendarMonthGrid_month__hideForAnimation + .CalendarMonthGrid_month__horizontal {
  border-right: 0;
}

.CalendarDay__default {
  border: none;
  font-size: 1rem;
}

.DayPickerSingleDateController .CalendarDay__selected,
.DayPickerSingleDateController .CalendarDay__default:hover,
.DayPickerRangeController .CalendarDay__selected,
.DayPickerRangeController .CalendarDay__default:hover {
  background-color: #fff;
  background-image: url('../images/react_dates/circle_orange.png');
  background-size: cover;
  border: 0;
  border-radius: 50%;
  color: #fff !important;
}

.DayPickerRangeController .CalendarDay__hovered_span,
.DayPickerRangeController .CalendarDay__hovered_span:hover,
.DayPickerRangeController .CalendarDay__selected_span,
.DayPickerRangeController .CalendarDay__selected_span:hover {
  background-color: #fff7e6;
  border-radius: 0;
  color: #484848;
  border: 0;
}

.DayPickerRangeController .CalendarDay__no_selected_start_before_selected_end:hover,
.DayPickerRangeController .CalendarDay__selected_start,
.DayPickerRangeController .CalendarDay__selected_start:hover,
.DayPickerRangeController .CalendarDay__selected_start_no_selected_end {
  background-color: #fff7e6;
  border-radius: 50% 0 0 50% !important;
}

.DayPickerRangeController .CalendarDay__selected_end,
.DayPickerRangeController .CalendarDay__selected_end:hover,
.DayPickerRangeController .CalendarDay__hovered_span:hover {
  background-color: #fff7e6;
  border-radius: 0 50% 50% 0;
}

.CalendarMonthGrid_month__hideForAnimation + .CalendarMonthGrid_month__horizontal {
  border-right: 1px solid #e5e5e5;
}

@media (max-width: 500px) {
  .CalendarMonthGrid_month__hideForAnimation + .CalendarMonthGrid_month__horizontal {
    border-right: 0;
  }
}

.CalendarMonthGrid_month__horizontal .CalendarMonth > .CalendarMonth_caption_1 {
  padding-top: 0;
  padding-bottom: 50px;
}

.DayPicker__horizontal_2.DayPicker__withBorder_3 {
  padding-top: 20px;
  margin-top: -1px;
  padding-bottom: 20px;
}

.DayPicker_weekHeader_ul.DayPicker_weekHeader_ul_1 {
  margin-top: -10px;
}
