@media screen and (min-width: 980px) {
  .background,
  .sidebar {
    display: none;
  }
}

.background {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #000;
  opacity: 0;
  transition: opacity 0.5s;
}

.overlay {
  z-index: 1000;
  opacity: 0.39;
}

.sidebar {
  top: 0;
  width: 0;
  height: 100%;
  z-index: 1001;
  position: fixed;
  overflow-y: auto;
  background: #fff;
  transition: width 0.5s;
  box-shadow: 0 2px 10px 0 rgba(123, 123, 123, 0.5);
}

.sidebar.show {
  width: 304px;
}

.sidebarContent {
  position: relative;
  opacity: 0;
  transition: opacity 2s;
}

.sidebarContent.show {
  opacity: 1;
}

.sidebarContent.hide {
  transition: opacity 0s;
}

.topSideBar {
  height: 64px;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #eeeff3;
}

.topSideBar > p {
  color: #ffffff;
  /* font-size: 1.375rem; */
  /* line-height: 24px; */
}

.topSideBar > button,
.topSideBar > a {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  padding: 4px 16px 4px 16px;
  background-color: #f9e13b;
  color: #173f8f;
  font-weight: 700;
  font-size: 16px;
}

.topSideBar > a:hover {
  text-decoration: none;
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  z-index: 10002;
  stroke: #173f8f;
  cursor: pointer;
}

.close > path {
  fill: #173f8f;
}

.staticPages a {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #173f8f;
  vertical-align: sub;
  display: flex;
  align-items: center;
  padding: 26px 16px;
  border-bottom: solid 1px #eeeff3;
}

.staticPages a:last-child {
  border-bottom: none;
}

.staticPages a.currentLink {
  background-color: #f1f2f6;
}

.staticPages a > span {
  margin-left: 10%;
  color: #173f8f;
  font-size: 14px;
}

.staticPages a:hover {
  text-decoration: none;
}

.staticPages hr {
  width: 100%;
  border-top: solid 1px #eeeff3;
  margin-top: 0px;
  margin-bottom: 0px;
}

.staticPages svg,
.staticPages img {
  width: 24px;
  height: 24px;
}

@media (max-width: 575px) {
  .sidebar.show {
    width: 100%;
  }

  .topSideBar p {
    font-size: 1.125rem;
    list-style: 20px;
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 16px;
    height: 16px;
    z-index: 10002;
    stroke: #173f8f;
    cursor: pointer;
  }

  .close > path {
    fill: #173f8f;
  }
}
