.select {
  position: relative;
  display: inline-block;
  /* margin-right: 10px; */
}

.select > .currentFlag {
  padding: 16px;
}

/* .select > .currentFlag:after {
  content: '';
  position: absolute;
  background-image: url('../../images/icons/arrow-select.svg');
  background-size: cover;
  width: 10px;
  height: 10px;
  right: -5px;
  top: 20px;
} */

.select > .flags {
  position: absolute;
  z-index: 999999;
  background: #fff;
  border: 1px solid #f4f4f4;
}

.select > .flags > div {
  padding: 10px 16px;
}

.select > .flags > div:hover {
  background-color: #f4f4f4;
}
