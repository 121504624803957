.rc-time-picker {
  width: 100%;
}

.rc-time-picker-input {
  border: none;
  color: #3d3d3d;
  font-size: 1.0625rem;
  line-height: 147.6%;
  padding: 0;
  height: auto;
  background-color: transparent;
}

.rc-time-picker-clear {
  display: none;
}
